<template>
  <div>
    <h2>* 사용자 리스트</h2>
    <b-card>
      <b-row>
        <b-col md="3" sm="8">
          <label for="roleStart">권한 시작</label>
          <b-form-datepicker
            id="roleStart"
            v-model="roleStart"
            size="sm"
            class="mb-2"
            today-button
          />
        </b-col>
        <b-col md="3" sm="8">
          <label for="roleEnd">권한 종료</label>
          <b-form-datepicker
            id="roleEnd"
            v-model="roleEnd"
            size="sm"
            class="mb-2"
          />
        </b-col>
        <b-col md="6">
          <label for="filterInput">검색</label>
            <b-input-group size="sm">
              <b-form-input
                id="filterInput"
                v-model="filter"
                type="search"
                placeholder="Type to Search"
              />
              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''">
                  Clear
                </b-button>
              </b-input-group-append>
            </b-input-group>
        </b-col>

        <b-col cols="12">
          <b-table
            :current-page="currentPage"
            :fields="adminHeader"
            :items="filteredAdminList"
            :per-page="perPage"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
          >
            <template #cell(selected)="{ rowSelected, item }">
              <template v-if="rowSelected">
                <label for="chk">
                  <input
                    id="chk"
                    :value="item.userId"
                    checked
                    type="checkbox"
                    @change="checkList(item.rowNum)"
                  >
                </label>
              </template>
              <template v-else>
                <input
                  :id="item.rowNum"
                  type="checkbox"
                  @change="checkList(item.rowNum)"
                >
                <label :for="item.rowNum" />
              </template>
            </template>
            <template v-slot:cell(userId)="row">
              <div style="cursor: pointer" @click="goDtl(row.value)">
                {{ row.value }}
              </div>
            </template>
          </b-table>

          <div class="tbFooter">
            <div class="delBtnArea">
              <b-button variant="danger" @click="delRole">
                삭제
              </b-button>
            </div>
            <div>
              <b-pagination
                v-model="currentPage"
                :per-page="perPage"
                :total-rows="adminList.length"
                aria-controls="my-table"
              />
            </div>
            <div class="regBtnArea">
              <b-button variant="primary" @click="goReg">
                등록
              </b-button>
            </div>
            <div class="regBtnArea">
              <b-button variant="warning" class="mr-1" @click="goList">
                돌아가기
              </b-button>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BCol,
  BPagination,
  BRow,
  BTable,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BFormGroup,
  BFormDatepicker,
} from 'bootstrap-vue';

export default {
  components: {
    BCol,
    BCard,
    BButton,
    BTable,
    BPagination,
    BRow,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BFormGroup,
    BFormDatepicker,
  },
  data() {
    return {
      selected: [],
      currentPage: 1,
      perPage: 10,
      filter: null,
      filterOn: [],
      roleStart: null,
      roleEnd: null,
    };
  },
  computed: {
    adminList() {
      return this.$store.state.admin.adminList;
    },
    adminHeader() {
      return this.$store.state.admin.adminHeader;
    },
    filteredAdminList() {
      return this.adminList.filter((item) => {
        if (this.roleStart && item.roleStart !== this.roleStart) {
          return false;
        }
        return !(this.roleEnd && item.roleEnd !== this.roleEnd);
      });
    },
  },
  watch: {

  },
  async created() {
    await this.$store.dispatch('admin/FETCH_ADMIN_LIST', {});
  },
  methods: {
    checkList(rowNum) {
      const idx = this.selected.indexOf(rowNum);
      if (idx >= 0) {
        this.selected.splice(idx, 1);
      } else {
        this.selected.push(rowNum);
      }
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async delRole() {
      const paramArr = this.selected;
      if (paramArr.length === 0) {
        alert('삭제할 역할을 선택해주세요.');
      } else {
        const res = await this.$store.dispatch('admin/FETCH_ADMIN_DELETE', {
          rowNums: paramArr,
        });
        if (res.resultFlag) {
          alert(res.resultMsg);
          await this.$router.push({ path: '/admin-list' });
        }
      }
    },
    async goDtl(item) {
      this.$router.push(`/admin-dtl?userId=${item}`);
    },
    goReg() {
      this.$router.push('/admin-reg');
    },
    goList() {
      this.$router.push('/');
    },

  },
};
</script>

<style scoped>
.tbFooter {
  text-align: center;
  padding: 30px 0;
}

.tbFooter div {
  display: inline-block;
}

.delBtnArea {
  float: left;
}

.regBtnArea {
  float: right;
}
</style>
